import React from 'react';
import { TextField, CircularProgress, Popper } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import styled from 'styled-components';
import classes from './Styles/dashboard.module.css';

const AutoCompleteIcon = styled(KeyboardArrowDownIcon)`
  font-size: 22px;
`;

const FilterField = ({
  placeholder = null,
  loading = null,
  className = classes.rootFilter,
  noOptionsText = 'No Results',
  onChangeTextField = null,
  size = 'small',
  width = null,
  label = null,
  popUpZIndex = null,
  variant = 'outlined',
  popPlacement = 'bottom-start',
  ...props
}) => {
    return (
      <Autocomplete
        noOptionsText={loading? 'Loading ...' : noOptionsText}
        className={className}
        style={{width : width? width : null}}
        popupIcon={<AutoCompleteIcon />}
        clearOnBlur={false}
        PopperComponent={({ style, ...props }) => (
          <Popper
            {...props}
            style={{ minWidth: 400, ...style, ...(popUpZIndex && {zIndex: popUpZIndex}) }}
            placement={popPlacement}
          />
        )}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={placeholder}
            size={size}
            variant={variant}
            label={label? label : null}
            onChange={onChangeTextField}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
        {...props}
      />
  );
};

export default React.memo(FilterField);