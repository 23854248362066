// @flow
import React, { type ComponentType, type Element, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation as useLocationDom } from 'react-router-dom';
import { useNotify } from 'react-admin';
import styled from 'styled-components';
import { IconButton, Hidden, Box } from '@material-ui/core';
import { dataProvider, hasKey, getStorage, setStorage, trackingUtils, dataPost } from 'helpers';
import { useLocation, useRecord } from 'hooks';
import { DarkMode as DarkModeIcon, WhiteMode, Warning as AlertIcon } from 'components/icons';
import { NotificationAlert, Warning, CustomTooltip, Button, ButtonCancel, Typography, Modal } from 'components';
import { NotificationsMenu } from 'apps/newsfeed/components';
import TermsModal from 'apps/newsfeed/components/license/TermsModal';
import * as newsFeed from 'helpers/apis/services/newsFeed';
import { BSN_SET_USER_ALL_NOTIFICATIONS_SEEN, BSN_UPDATE_DARK_MODE } from 'conf';
import * as usersAPI from 'helpers/apis/services/usersAPI';
import AppBreadcrumbs from '../AppBreadcrumbs';
import useHasInternet from '../../../hooks/useHasInternet';
import MenuIcon from '../Menu/MenuIcon';
import { useThemeContext } from '@trustsecurenow/components-library';
import ClientSystemEmailsAlert from '../../../apps/clients/presets/tables/ClientSystemEmailsAlert';
import { SRA_SUB_PAGES } from '../../pages/SRA/constants';

export const Header: ComponentType<any> = styled.header`
  position: ${({ centered }) => (centered ? 'relative' : 'sticky')};
  width: 100%;
  /* max-width: 1016px; */
  background-color: var(--backgroundDefault);
  display: flex;
  z-index: 1100;
  box-sizing: border-box;
  flex-shrink: 0;
  color: var(--colorDefault);
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: calc(var(--spacing) * 2);
  padding-bottom: 0;
  top: 0;
`;

export const MenuIconContainer: ComponentType<any> = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const HeaderTitle: ComponentType<any> = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding-right: 20px;

  h1 {
    font-size: 2.5rem;
    text-align: left;
    letter-spacing: 0px;
    color: var(--colorBase);
    margin: 0;
    margin-left: 40px;
    word-break: break-all;
  }
`;

export const ImageFilter: ComponentType<*> = styled.img`
  filter: var(--filterImage);
  height: calc(var(--spacing) * 5);
`;

const NotificationDot: ComponentType<*> = styled.span`
  position: absolute;
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #ff5a61;
  border-radius: 50%;
  top: 10px;
  right: 10px;
`;

type AppBarProps = {
  width: string,
  centered?: boolean
};

const APPS_WITH_BREADCRUMBS = ['admin', 'auditlog', 'getStarted', 'contentadmin', 'reports'];
const TABS_WITH_BREADCRUMBS = {
  partnerProfile: ['configure'],
  clients: ['directorySync', 'welcomeSync', 'csvBulkUpload', 'welcomeMessage'],
  myCompany: ['directorySync', 'csvBulkUpload', 'welcomeMessage']
};
const SUBPAGES_WITH_BREADCRUMBS = {
  myCompany: {
    sra: SRA_SUB_PAGES
  },
  clients: {
    sra: SRA_SUB_PAGES
  }
};

function showBreadcrumbs(app, tab, item, pathname) {
  const subpage = pathname?.split('/')?.[4];
  const isApp = APPS_WITH_BREADCRUMBS.includes(app);
  const isTab = TABS_WITH_BREADCRUMBS[app] && TABS_WITH_BREADCRUMBS[app].includes(tab);
  const isSubage =
    SUBPAGES_WITH_BREADCRUMBS[app] &&
    SUBPAGES_WITH_BREADCRUMBS[app][tab] &&
    (SUBPAGES_WITH_BREADCRUMBS[app][tab].includes(item) || SUBPAGES_WITH_BREADCRUMBS[app][tab].includes(subpage));
  return isApp || isTab || isSubage;
}

const AppBar = ({ centered, isNotificationsOpen,
  toggleNotificationDrawer, isTermsOfUseDialogOpen, onToggleOpenTermsDialog }: AppBarProps): Element<*> => {
  const isDarkMode = getStorage('darkMode') === 'true';
  const dispatch = useDispatch();
  const { setThemeMode } = useThemeContext();
  const notify = useNotify();
  const { app, tab, item } = useLocation();
  const { pathname } = useLocationDom();
  const maskPartnerName = getStorage('maskPartnerName', true);
  const information = useRecord('clients', 'information');
  const logo = useSelector(({ bsn: { user: { profile } } }) => (profile ? profile.logo_product : null));
  const [darkMode, setDarkMode] = useState(isDarkMode);
  const [darkModeChanged, setDarkModeChanged] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [app_bar_state, setAppBarState] = React.useState({
    isEnableEmailModalOpen: false,
    isEnableEmailModalPending: false,
    disabled_system_email: false,
    is_client_active: false
  });

  const hasInternet = useHasInternet();
  const clientName = useSelector(({ bsn }) => bsn?.user?.profile?.client_name);
  const userDarkMode = useSelector(({ bsn }) => bsn?.user?.profile?.dark_mode);
  const showDarkModeToggle = useSelector(({ bsn }) => bsn?.user?.access?.features?.show_dark_mode_switch);
  const newsfeedAccess = useSelector(({ bsn }) => bsn?.user?.access?.apps?.newsfeed);
  const allNewsfeedNotificationsSeen = useSelector(({ bsn }) => bsn?.user?.profile?.all_newsfeed_notifications_seen);
  const newsfeedLicense = useSelector(state => state.newsfeed.license);
  const showNewsfeedIcons = newsfeedAccess && app === 'newsfeed';
  const headerTitle = companyName
    ? information !== null && hasKey(information, 'name')
      ? information.name
      : companyName
    : clientName;

  useEffect(() => {
    if (typeof userDarkMode === 'boolean' && !darkModeChanged) {
      setDarkMode(userDarkMode);
      setThemeMode(userDarkMode ? 'dark' : 'light');
      setStorage('darkMode', userDarkMode, true);
    }
  }, [darkModeChanged, userDarkMode]);

  useEffect(() => {
    if (document.body) {
      document.body.setAttribute('data-theme', darkMode ? 'dark' : 'light');
      document.querySelector("meta[name='theme-color']").setAttribute('content', darkMode ? '#191a20' : '#f3f5f6');
    }
  }, [darkMode]);

  useEffect(() => {
    if (app === 'clients') {
      if (item && (companyName === null || companyName === maskPartnerName)) {
        dataProvider
          .getOne('clients', `company/${item}`)
          .then(resp => {
            hasInternet(() => {
              if (typeof resp === 'object' && hasKey(resp, 'data')) {
                setCompanyName(resp.data.name);
                setAppBarState(old => {
                  return {
                    ...old,
                    disabled_system_email: Boolean(resp.data.disabled_system_email),
                    is_client_active: Boolean(resp.data.is_client_active)
                  };
                });
              }
            });
          })
          .catch(err => {
            if(err.message === 'Request aborted') {
              window.location.reload();
              return;
            }
            window.location.href = '/#/myDashboard/dashboard';
          });
      }

      if (!item) {
        setCompanyName(maskPartnerName || null);
        setAppBarState(old => {
          return { ...old, disabled_system_email: false, is_client_active: false };
        });
      }
    } else if (companyName) {
      setCompanyName(null);
      setAppBarState(old => {
        return { ...old, disabled_system_email: false, is_client_active: false };
      });
    }
  }, [app, companyName, hasInternet, item, maskPartnerName, notify]);

  const onChangeTheme = () => {
    setDarkMode(!darkMode);
    setThemeMode(darkMode ? 'light' : 'dark');
    setStorage('darkMode', !darkMode, true);
    setDarkModeChanged(true);
    usersAPI
      .manageUserDarkModeData(!darkMode)
      .then(res => {
        dispatch({ type: BSN_UPDATE_DARK_MODE, payload: !darkMode });
      })
      .catch(err => {
        const errorMsg = err?.response?.data?.message;
        if (errorMsg) notify(errorMsg, 'warning');
      });
    trackingUtils.clickButton(`${darkMode ? 'Disable' : 'Enable'} Dark Mode`);
  };

  const onOpenNotifications = () => {
    toggleNotificationDrawer(true);
    if (!allNewsfeedNotificationsSeen) {
      newsFeed.markAllSeen().then(res => {
        dispatch({
          type: BSN_SET_USER_ALL_NOTIFICATIONS_SEEN,
          payload: 1
        });
      });
    }
  };

  const onOpenTermsModal = () => {
    onToggleOpenTermsDialog(true);
  };

  const onCloseTermsModal = () => {
    onToggleOpenTermsDialog(false);
  };

  return (
    <>
      <Hidden smDown>
        <Header centered={centered}>
          {!centered && (
            <Hidden mdUp>
              <MenuIconContainer>
                <MenuIcon />
              </MenuIconContainer>
            </Hidden>
          )}
          {!centered && (
            <>
              {headerTitle && (
                <HeaderTitle>
                  {showBreadcrumbs(app, tab, item, pathname) ? (
                    <AppBreadcrumbs app={app} tab={tab} item={item} />
                  ) : (
                    <CustomTooltip title={headerTitle.length > 50 ? headerTitle : ''} placement="bottom">
                      <h1 style={{ whiteSpace: 'nowrap' }}>
                        {headerTitle.length > 50 ? `${headerTitle.substring(0, 50)}...` : headerTitle}
                      </h1>
                    </CustomTooltip>
                  )}
                </HeaderTitle>
              )}
              {showNewsfeedIcons ? (
                <>
                  {newsfeedLicense?.newsfeed_license_agreed && newsfeedLicense?.show_license_agreement_dialog ? (
                    <>
                      <IconButton style={{ padding: 8, marginRight: 8 }} onClick={onOpenTermsModal}>
                        <Warning size={1.715} color="colorIcon" />
                      </IconButton>
                      <TermsModal open={isTermsOfUseDialogOpen} close={onCloseTermsModal} />
                    </>
                  ) : null}
                  <IconButton style={{ padding: 8, marginRight: 8 }} onClick={onOpenNotifications}>
                    <NotificationAlert size={1.715} color="colorIcon" />
                    {allNewsfeedNotificationsSeen ? null : <NotificationDot />}
                  </IconButton>
                  <NotificationsMenu isOpen={isNotificationsOpen} onClose={() => toggleNotificationDrawer(false)} />
                </>
              ) : null}
              {showDarkModeToggle && (
                <CustomTooltip
                  title={darkMode ? 'Disable Dark Mode' : 'Enable Dark Mode'}
                  placement="bottom"
                  disableClickListener
                >
                  <IconButton style={{ padding: 8, marginRight: 8 }} onClick={onChangeTheme}>
                    <DarkModeIcon size={1.715} color={darkMode ? 'colorCommonWhite' : 'colorIcon'} />
                  </IconButton>
                </CustomTooltip>
              )}
              {logo && (
                <Box ml={1.7} display="flex" alignItems="center">
                  <ImageFilter src={logo} alt="Logo Partner" />
                </Box>
              )}
            </>
          )}
        </Header>
      </Hidden>
      <ClientSystemEmailsAlert setAppBarState={setAppBarState} appBarState={app_bar_state} companyName={companyName} />
    </>
  );
};

AppBar.defaultProps = {
  centered: false
};

export default AppBar;
