import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';

export const PRESETS_COLUMNS = {
  NAME: 'Name',
  ACTIVE: 'Active',
  PRODUCT: 'Product',
  TEAMS_APP: 'Teams App',
  SYSTEM_EMAILS: 'System Emails',
  COMPANY_ESS: 'Company ESS',
  USERS_BREACHES: 'Users w/ Breaches',
  TOTAL_BREACHES: 'Total # of breaches',
  AVG_PFR: 'PFR%',
  AVG_SECURITY_TRAINING: 'Avg. Security Training Score',
  SECURITY_TRAINING_COMPLETION: 'Security Training Completion',
  AVG_MICRO_QUIZ: 'Avg. Micro Training Score',
  MT_COMPLETION: 'Micro Training Completion',
  AUTOMATED_ESS_REPORT: 'Automated ESS Report',
  ESS_REPORT: 'ESS Report',
  CLIENT_REPORT: 'Client Report',
  HSN_BRANDING: 'HSN Branding',
  HSN_CONSULTING: 'HSN Consulting',
  ACTIVE_USERS: 'Active users',
  INDUSTRY: 'Industry',
  SRA_COMPLETION_DATE: 'SRA Completion Date',
  DIRECTORY_SYNC: 'Directory Sync',
  SINGLE_SIGNON: 'Single Sign-On',
  WELCOME_MESSAGE: 'Welcome Message',
  RECEIVED_WELCOME_MESSAGE: 'Received Welcome Message',
  MT_NL: 'Micro Training / Newsletter',
  POSITIVE_OPTIN: 'Positive Opt-in',
  DIRECT_MAIL_DELIVERY: 'Direct Mail Delivery',
  PHISHING_CAMPAIGN_USERS: 'Users in Recurring Phishing Campaign',
  DW_MONITORING: 'Dark Web Monitoring',
  AUTOMATED_REPORTS_REMINDERS: 'Automated Reports and Reminders',
  USERS_RECEIVING_MTNL: 'Received MT/NL',
  USERS_BOUNCED_EMAIL: 'Users w/ Bounced Email',
  DELETED: 'Deleted'
};

export const TABLE_NAME = 'MANAGE_CLIENTS_TABLE';
export const ESS_VIEW_KEY = 'MANAGE_CLIENTS_TABLE_ESS_VIEW';
export const ACCOUNT_OVERVIEW_VIEW_KEY = 'MANAGE_CLIENTS_TABLE_ACCOUNT_OVERVIEW_VIEW';
export const DEPLOYMENT_STATS_VIEW_KEY = 'MANAGE_CLIENTS_TABLE_DEPLOYMENT_STATS_VIEW';
export const MTNL_VIEW_KEY = 'MANAGE_CLIENTS_TABLE_MTNL_VIEW';

const ACCOUNT_OVERVIEW_DEFAULT_COLUMNS = [
  PRESETS_COLUMNS.NAME,
  PRESETS_COLUMNS.ACTIVE,
  PRESETS_COLUMNS.DELETED,
  PRESETS_COLUMNS.SYSTEM_EMAILS,
  PRESETS_COLUMNS.PRODUCT,
  PRESETS_COLUMNS.TEAMS_APP,
  PRESETS_COLUMNS.HSN_BRANDING,
  PRESETS_COLUMNS.HSN_CONSULTING,
  PRESETS_COLUMNS.ACTIVE_USERS,
  PRESETS_COLUMNS.COMPANY_ESS,
  PRESETS_COLUMNS.INDUSTRY,
  PRESETS_COLUMNS.CLIENT_REPORT
];

const ESS_DEFAULT_COLUMNS = [
  PRESETS_COLUMNS.NAME,
  PRESETS_COLUMNS.ACTIVE,
  PRESETS_COLUMNS.DELETED,
  PRESETS_COLUMNS.SYSTEM_EMAILS,
  PRESETS_COLUMNS.PRODUCT,
  PRESETS_COLUMNS.COMPANY_ESS,
  PRESETS_COLUMNS.USERS_BREACHES,
  PRESETS_COLUMNS.TOTAL_BREACHES,
  PRESETS_COLUMNS.AVG_PFR,
  PRESETS_COLUMNS.AVG_SECURITY_TRAINING,
  PRESETS_COLUMNS.SECURITY_TRAINING_COMPLETION,
  PRESETS_COLUMNS.AVG_MICRO_QUIZ,
  PRESETS_COLUMNS.MT_COMPLETION,
  PRESETS_COLUMNS.AUTOMATED_ESS_REPORT,
  PRESETS_COLUMNS.ESS_REPORT,
  PRESETS_COLUMNS.CLIENT_REPORT
];

const DEPLOYMENT_STATS_DEFAULT_COLUMNS = [
  PRESETS_COLUMNS.NAME,
  PRESETS_COLUMNS.ACTIVE,
  PRESETS_COLUMNS.DELETED,
  PRESETS_COLUMNS.SYSTEM_EMAILS,
  PRESETS_COLUMNS.PRODUCT,
  PRESETS_COLUMNS.TEAMS_APP,
  PRESETS_COLUMNS.DIRECTORY_SYNC,
  PRESETS_COLUMNS.SINGLE_SIGNON,
  PRESETS_COLUMNS.WELCOME_MESSAGE,
  PRESETS_COLUMNS.RECEIVED_WELCOME_MESSAGE,
  PRESETS_COLUMNS.MT_NL,
  PRESETS_COLUMNS.POSITIVE_OPTIN,
  PRESETS_COLUMNS.DIRECT_MAIL_DELIVERY,
  PRESETS_COLUMNS.PHISHING_CAMPAIGN_USERS,
  PRESETS_COLUMNS.DW_MONITORING,
  PRESETS_COLUMNS.AUTOMATED_REPORTS_REMINDERS,
  PRESETS_COLUMNS.CLIENT_REPORT,
  PRESETS_COLUMNS.ESS_REPORT
];

const MTNL_DEFAULT_COLUMNS = [
  PRESETS_COLUMNS.NAME,
  PRESETS_COLUMNS.ACTIVE,
  PRESETS_COLUMNS.DELETED,
  PRESETS_COLUMNS.SYSTEM_EMAILS,
  PRESETS_COLUMNS.PRODUCT,
  PRESETS_COLUMNS.MT_NL,
  PRESETS_COLUMNS.USERS_RECEIVING_MTNL,
  PRESETS_COLUMNS.USERS_BOUNCED_EMAIL,
  PRESETS_COLUMNS.POSITIVE_OPTIN,
  PRESETS_COLUMNS.WELCOME_MESSAGE,
  PRESETS_COLUMNS.RECEIVED_WELCOME_MESSAGE,
  PRESETS_COLUMNS.AVG_MICRO_QUIZ,
  PRESETS_COLUMNS.MT_COMPLETION,
  PRESETS_COLUMNS.CLIENT_REPORT,
  PRESETS_COLUMNS.ESS_REPORT
];

const ACCOUNT_OVERVIEW_COLUMNS = [
  ...new Set([
    ...ACCOUNT_OVERVIEW_DEFAULT_COLUMNS,
    ...ESS_DEFAULT_COLUMNS,
    ...DEPLOYMENT_STATS_DEFAULT_COLUMNS,
    ...MTNL_DEFAULT_COLUMNS
  ]),
  PRESETS_COLUMNS.SRA_COMPLETION_DATE
];

export const TABLE_VIEWS = {
  [ACCOUNT_OVERVIEW_VIEW_KEY]: {
    name: 'Account Overview',
    columns: ACCOUNT_OVERVIEW_COLUMNS,
    defaultColumns: ACCOUNT_OVERVIEW_DEFAULT_COLUMNS
  },
  [DEPLOYMENT_STATS_VIEW_KEY]: {
    name: 'Deployment Stats',
    columns: DEPLOYMENT_STATS_DEFAULT_COLUMNS,
    defaultColumns: DEPLOYMENT_STATS_DEFAULT_COLUMNS
  },
  [ESS_VIEW_KEY]: {
    name: 'Employee Secure Score (ESS)',
    columns: ESS_DEFAULT_COLUMNS,
    defaultColumns: ESS_DEFAULT_COLUMNS
  },
  [MTNL_VIEW_KEY]: {
    name: 'Micro Trainings / Newsletter Engagement',
    columns: MTNL_DEFAULT_COLUMNS,
    defaultColumns: MTNL_DEFAULT_COLUMNS
  }
};

// values are filenames
export const MODALS = {
  ACTIVATE_CLIENT: 'ActivateClientDialog',
  ACTIVATE_CLIENT2: 'ActivateClientDialog2',
  DEACTIVATE_CLIENT: 'DeactivateClientDialog',
  DELETE_CLIENT: 'DeleteClientDialog',
  SELECT_INDUSTRY: 'IndustryDialog',
  ENABLE_SYSTEM_EMAILS: 'EnableSystemEmailsDialog',
  SEND_WELCOME_MESSAGE: 'SendWelcomeMessageDialog',
  VIEW_USERS: 'ViewUsersDialog',
  POSITIVE_OPTIN: 'PositiveOptInDialog',
  BOUNCED_EMAILS: 'BouncedMailDialog',
  MICRO_TRAINING: 'MicroTrainingDialog',
  MICRO_TRAINING_ENABLED: 'MicroTrainingsEnabledDialog',
  BULK_DISABLE_CLIENT: 'BulkDisableClientDialog',
  BULK_ENABLE_WELCOME_MESSAGE: 'BulkEnableWelcomeMessage',
  BULK_ENABLE_MICRO_TRAINING: 'BulkEnableMicroTraining',
  DISABLE_WELCOME_MESSAGE: 'DisableWelcomeMessage',
  ENABLE_WELCOME_MESSAGE: 'EnableWelcomeMessage',
  UNDELETE_CLIENT: 'UndeleteClientDialog',
  TEAMS_APP_DEPLOYMENT_INSTRUCTIONS: 'TeamsAppDeploymentInstructionsDialog',
  TEAMS_APP_ENGAGEMENT_REMINDER: 'TeamsAppEngagementReminderDialog',
  TEAMS_APP_USER_NOTIFIED_DIALOG: 'TeamsAppUserNotifiedDialog',
};

export const BULK_ACTIONS = {
  ENABLE_CLIENT: 'Enable Client',
  DISABLE_CLIENT: 'Disable Client',
  DELETE_CLIENT: 'Delete Client',
  DOWNLOAD_CLIENT_REPORT: 'Download Client Report',
  DOWNLOAD_EMPLOYEE_SECURE_SCORE_REPORT: 'Download Employee Secure Score Report',
  ENABLE_WELCOME_MESSAGE: 'Enable Welcome Message',
  SEND_WELCOME_MESSAGE: 'Send Welcome Message',
  ENABLE_MT_NL: 'Enable Micro Training and Newsletter Emails',
  ENABLE_POSITIVE_OPTIN: 'Enable Positive Opt-in',
  BOUNCED_EMAILS: 'Clear Bounced Emails'
};

export const BULK_MENU_ACTION_TYPES = {
  OPEN_MODAL: 'OPEN_MODAL',
  DIRECT_ACTION: 'DIRECT_ACTION'
};

export const ALWAYS_VISIBLE_BULK_ACTIONS = [
  BULK_ACTIONS.DELETE_CLIENT,
  BULK_ACTIONS.DOWNLOAD_CLIENT_REPORT,
  BULK_ACTIONS.DOWNLOAD_EMPLOYEE_SECURE_SCORE_REPORT
];

export const MODALS_INITIAL_STATE = {
  [MODALS.ACTIVATE_CLIENT]: {
    open: false,
    clientId: null,
    clientName: null,
    submitting: false,
    selectedClientIds: null
  },
  [MODALS.ACTIVATE_CLIENT2]: {
    open: false,
    clientId: null,
    clientName: null,
    submitting: false,
    submittingWithSystemEmailsEnabled: false
  },
  [MODALS.DEACTIVATE_CLIENT]: {
    open: false,
    clientId: null,
    submitting: false,
    isCancellableHIPAA: false
  },
  [MODALS.DELETE_CLIENT]: {
    open: false,
    clientId: null,
    clientName: null,
    submitting: false,
    selectedClientIds: null
  },
  [MODALS.SELECT_INDUSTRY]: {
    open: false,
    clientId: null,
    selectedIndustryId: '',
    submitting: false
  },
  [MODALS.ENABLE_SYSTEM_EMAILS]: {
    open: false,
    clientId: null,
    clientName: null,
    submitting: false
  },
  [MODALS.SEND_WELCOME_MESSAGE]: {
    open: false,
    clientId: null,
    clientName: null,
    usersWithNoWelcomeMessage: null,
    selectedUsersCount: null,
    filteredUserIds: null,
    viewUsersSubmitting: false,
    isLoadingUsersCount: undefined,
    parentModalName: null,
    submitting: false,
    welcomeMessageType: null,
    selectedClientIds: null,
    activeUsersCount: 0,
    isLoadingActiveUsersCount: false
  },
  [MODALS.VIEW_USERS]: {
    open: false,
    usersList: [],
    clientName: null,
    parentModalName: null
  },
  [MODALS.POSITIVE_OPTIN]: {
    open: false,
    clientId: null,
    clientName: null,
    submitting: false,
    parentModalName: null,
    selectedClientIds: null
  },
  [MODALS.BOUNCED_EMAILS]: {
    open: false,
    clientId: null,
    clientName: null,
    submitting: false,
    bouncedEmailPercent: null,
    totalUsersCount: null,
    viewUsersSubmitting: false,
    parentModalName: null,
    selectedUsersCount: null,
    filteredUserIds: null,
    selectedClientIds: null
  },
  [MODALS.MICRO_TRAINING]: {
    open: false,
    clientId: null,
    clientName: null,
    loadingModal: false,
    totalUsersCount: null,
    isPositiveOptInEnabled: false,
    bouncedEmailUsersCount: null,
    isClientMTNLDisabled: null,
    mtnlDisabledUsersCount: null,
    noWelcomeMessageUsersCount: null,
    welcomeMessageSuccess: false,
    positiveOptinSuccess: false,
    bouncedEmailSuccess: false,
    mtnlSuccess: false
  },
  [MODALS.MICRO_TRAINING_ENABLED]: {
    open: false,
    clientId: null,
    usersWithNoMtNl: null,
    parentModalName: null,
    submitting: false
  },
  [MODALS.BULK_DISABLE_CLIENT]: {
    open: false,
    submitting: false,
    selectedClientIds: null
  },
  [MODALS.BULK_ENABLE_WELCOME_MESSAGE]: {
    open: false,
    submitting: false,
    selectedClientIds: null
  },
  [MODALS.BULK_ENABLE_MICRO_TRAINING]: {
    open: false,
    submitting: false,
    selectedClientIds: null
  },
  [MODALS.ENABLE_WELCOME_MESSAGE]: {
    open: false,
    clientId: null,
    submitting: false
  },
  [MODALS.DISABLE_WELCOME_MESSAGE]: {
    open: false,
    clientId: null,
    submitting: false
  },
  [MODALS.UNDELETE_CLIENT]: {
    open: false,
    clientId: null,
    submitting: false
  },
  [MODALS.TEAMS_APP_DEPLOYMENT_INSTRUCTIONS]: {
    open: false,
    loading: false,
    clientId: null,
    isHSN: false
  },
  [MODALS.TEAMS_APP_ENGAGEMENT_REMINDER]: {
    open: false,
    loading: false,
    clientId: null,
    usersList: [],
    isHSN: false
  },
  [MODALS.TEAMS_APP_USER_NOTIFIED_DIALOG]: {
    open: false,
    titleText: null,
    isHSN: false,
  }
};

export const FEDERATION_SERVICES = {
  O365: 'O365',
  GOOGLE: 'Google',
  OKTA: 'Okta'
};

export const AZURE_ACTIVE_DIRECTORY_SERVICES = {
  MULTI_TENANT: 'multitenant',
  APP_ID: 'AppID',
  SYNC_2: 'sync2',
};

export const PRODUCT_TYPES = {
  UT: 'Unlimited Cybersecurity Training',
  HIPAA_COMPLIANCE: 'HIPAA Compliance',
  HIPAA_BPP: 'HIPAA BPP',
  EVA_MD: 'EVA MD',
  BPP: 'Breach Prevention Platform (BPP)'
};

export const CANCELLABLE_HIPAA_PRODUCTS = [
  PRODUCT_TYPES.HIPAA_BPP,
  PRODUCT_TYPES.HIPAA_COMPLIANCE,
  PRODUCT_TYPES.EVA_MD
];

export const TEAMS_APP_ALLOWED_PRODUCT_TYPES = {
  BPP: 'Breach Prevention Platform (BPP)',
  HIPAA_BPP: 'HIPAA BPP',
  EVA_MD: 'EVA MD',
  PARTNER_HIPAA_COMPLIANCE: 'Partner HIPAA Compliance Program'
};

export const SEARCH_FIELDS = ['name'];

export const PINNED_FIELDS = [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'name'];

export const COLUMN_SELECTOR_HIDDEN_FIELDS = [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'name', 'active', 'product_type'];

export const BULK_ACTIONS_FIELDS = ['id', 'active', 'product_type', 'is_internal']; // always needed for bulk actions calculations